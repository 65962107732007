import {computed} from "vue";
import store from "@/store";

function formatUserFullName(profile) {
  return [profile?.first_name??'', profile?.middle_name??'', profile?.second_name??'']
    .filter(str => str&&str.trim().length).join(' ');
}

/**
 *
 * @param address
 * @returns {string}
 */
function formatAddress(address) {
  // [Номер дома] [Название улицы] [номер квартиры или комнаты, если есть] [Штат] [индекс]
  // [address.street, address.apartment, address.state, address.zip_first, address.zip_second]
  return [address?.street, address?.apartment, address?.state, address?.zip_first, address?.zip_second].filter(el=>el).join(' ');
}

const operatorUserId = computed(()=> store.getters.user.id);

const rules = computed(()=>store.getters.accessTokenData?.roles||[]);
const isAdmin = computed(()=> rules.value.indexOf('admin') !== -1);

export {
  formatUserFullName, formatAddress, operatorUserId,
  isAdmin,
};
