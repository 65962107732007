import {createStore} from "vuex";

import servicesApi from '@/services/api';
import usersApi from '@/services/users';
import bureausApi from '@/services/bureaus';
import customersApi from '@/services/customers';
import reportsApi from '@/services/reports';
import blobCache from "@/services/blobCache";
import dashboardApi from "@/services/dashboard";
import businessApi from "@/services/business";
import staticsApi from "@/services/statics";
import stateApi from '@/services/state';
import operatorsApi from '@/services/operators';
import userDataApi from '@/services/userData';
import disputesApi from '@/services/disputes';


export default createStore({
  state: {
    message:{
      info: null,
      success: null,
      warning: null,
      error: null
    },
    preloader: {},
  },
  mutations: {
    setError(state, error) {
      state.message.error = error
    },
    clearError(state) {
      state.message.error = null
    },
    setMessage(state, [type, message]) {
      state.message[type] = message
    },
    clearMessage(state, type) {
      state.message[type] = null
    },
    onPreloader(state, type) {
      state.preloader[type] = true;
    },
    offPreloader(state, type) {
      state.preloader[type] = false;
    },
  },
  getters: {
    error: s => s.message.error,
    messages: s => s.message,
    preloader: s => s.preloader,
  },
  actions: {
    catchError({commit}, error) {
      console.error("catchError:", error);
      if (error.response.data === undefined) {
        commit('setError', error.message);
      }else {
        if (typeof error.response.data.detail === 'string') {
          commit('setError', error.response.data.detail);
        } else {
          if (typeof error.response.data.detail === 'object') {
            error.response.data.detail.forEach(item => {
              commit('setError', `location: ${item.loc.join(', ')}<br> message: ${item.msg}`);
            });
          } else {
            commit('setError', error);
          }
        }
      }
      throw error;
    },
  },
  modules: {
    stateApi,
    dashboardApi, businessApi,
    servicesApi, usersApi,
    bureausApi, customers: customersApi,
    blobCache, reportsApi, staticsApi,
    operatorsApi, userDataApi, disputesApi,

  }
});
