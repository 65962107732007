import apiAxios from "@/api-axios";

export default {
  state: {
    userDataTypes: [],
  },
  mutations: {
    setUserDataTypes(state, userDataTypes) {
      state.userDataTypes = userDataTypes;
    },
  },
  getters: {
    userDataTypes: s => s.userDataTypes,
  },
  actions: {
    async apiGetUserDataTypes({commit, dispatch}, params) {
      try {
        const response = await apiAxios.get('/user_data/types/', params);
        commit('setUserDataTypes', response.data);
      } catch (e) {
        dispatch('catchError', e);
      }
    },
  }
}
