import apiAxios from "@/api-axios";

export default {
  state: {
    customerBureauStatus: {},
    businessBureauStatuses: [],
    operatorDashboardData: {},
  },
  mutations: {
    setCustomerBureauStatus(state, customers) {
      state.customerBureauStatus = customers;
    },
    clearCustomerBureauStatus(state) {
      state.customerBureauStatus = {};
    },
    setBusinessBureauStatuses(state, companies) {
      state.businessBureauStatuses = companies;
    },
    clearBusinessBureauStatuses(state) {
      state.businessBureauStatuses = [];
    },
    setOperatorDashboardData(state, data) {
      state.operatorDashboardData = data;
    },
    clearOperatorDashboardData(state) {
      state.operatorDashboardData = {};
    },
  },
  getters: {
    customerBureauStatus: s => s.customerBureauStatus,
    businessBureauStatuses: s => s.businessBureauStatuses,
    operatorDashboardData: s => s.operatorDashboardData,
  },
  actions: {
    async apiGetDashboardStatus({commit, dispatch}, params) {
      try {
        const response = await apiAxios.get('/dashboard/status/', params, apiAxios.getParamsSerializerConfig());
        const customerBureauStatus = {};
        for (let customer of response.data) {
          customerBureauStatus[customer.userId] = customer;
        }
        await commit('setCustomerBureauStatus', customerBureauStatus);
        return response.data;
      } catch (e) {
        await commit('clearCustomerBureauStatus');
        dispatch('catchError', e);
      }
      return [];
    },
    async apiGetDashboardBusinessStatus({commit, dispatch}, params) {
      try {
        const response = await apiAxios.get('/dashboard/business/status/', params, apiAxios.getParamsSerializerConfig());
        await commit('setBusinessBureauStatuses', response.data);
        return response.data;
      } catch (e) {
        await commit('clearBusinessBureauStatuses');
        dispatch('catchError', e);
      }
      return [];
    },
    async apiGetDashboardUpdate({dispatch}, params) {
      try {
        if (!params.lastUpdatedAt.length) {
          delete params.lastUpdatedAt;
        }
        const response = await apiAxios.get('/dashboard/update/', params, apiAxios.getParamsSerializerConfig());
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiSetDashboardData({dispatch}, {value}) {
      try {
        const response = await apiAxios.post('/dashboard/settings/', {value});
        return response.data;
      }catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiGetDashboardData({dispatch}) {
      try {
        const response = await apiAxios.get('/dashboard/settings/');
        return response.data||{};
      }catch (e) {
        dispatch('catchError', e);
      }
      return {};
    }

  }
}
