import apiAxios from "@/api-axios";

export default {
  actions: {
    async apiGetOperators({dispatch}, params) {
      try {
        const response = await apiAxios.get('/operators/', params);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return [];
    },
    async apiCreateOperator({dispatch}, data) {
      try {
        const response = await apiAxios.post('/operators/', data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return [];
    },
    async apiGetOperator({dispatch}, operatorId) {
      try {
        const response = await apiAxios.get(`/operators/${operatorId}`);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiPatchOperator({dispatch}, {operatorId, data}) {
      try {
        const response = await apiAxios.patch(`/operators/${operatorId}/`, data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiPatchOperatorPassword({dispatch}, {operatorId, password}) {
      try {
        const response = await apiAxios.post(`/operators/${operatorId}/`, {password});
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiPatchOperatorMePassword({dispatch}, {operatorId, pwd}) {
      try {
        const response = await apiAxios.post(`/operators/${operatorId}/`, pwd);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiActionActive({dispatch}, operatorId) {
      return await dispatch('apiActionStatus', {operatorId, data:{is_active: true}});
    },
    async apiActionPaused({dispatch}, operatorId) {
      return await dispatch('apiActionStatus', {operatorId, data:{is_active: false, archived: false}});
    },
    async apiActionBlocked({dispatch}, operatorId) {
      return await dispatch('apiActionStatus', {operatorId, data: {archived: true}});
    },
    async apiActionStatus({dispatch}, {operatorId, data}) {
      try {
        const response = await apiAxios.patch(`/operators/${operatorId}/status`, data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return false;
    },
    async apiActionDelete({dispatch}, operatorId) {
      try {
        const response = await apiAxios.delete(`/operators/${operatorId}`);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return false;
    },
  }
}
