import {createRouter, createWebHistory} from "vue-router";
import store from "@/store";
import {offGlobalPreloader, onGlobalPreloader} from "@/utils/global-preload-mixin";
import {isAdmin} from "@/utils/user";

async function loadData() {
  try {
    await store.commit('setAccessToken', localStorage.getItem('token') || '')
    if (store.getters.accessToken.length) {
      if (!store.getters.profile?.email_address) {
        await onGlobalPreloader();
        await store.dispatch('apiGetUserData');
      }
      if (!store.getters.businessBureaus.length) {
        await onGlobalPreloader();
        await store.dispatch('apiGetBusinessBureaus');
      }
      if (!store.getters.bureaus.length) {
        await onGlobalPreloader();
        await store.dispatch('apiGetBureaus');
      }
    }
  } finally {
    await offGlobalPreloader();
  }
}

function adminGuardRoute(to, next) {
  if (to.meta?.isAdmin) {
    if (isAdmin.value) {
      next();
    }else {
      store.commit('setMessage', ['warning', 'Access denied']);
      next(false);
    }
  } else {
    next();
  }
}

function guardRoute(to, from, next) {
  if (to.meta.isAuth && !store.getters.accessToken) {
    if (localStorage === undefined || !localStorage.getItem('token')) {
      next({name: 'sign-in'});
      return;
    }
  }
  loadData().then(() => {
    let names = ['sign-in', 'sing-up', 'forgot-password', 'reset-password'];
    if (names.indexOf(to.name) !== -1 && store.getters.accessToken) {
      next({name: 'dashboard'});
    } else {
      adminGuardRoute(to, next);
    }
  }).catch(error => {
    console.error('Error when load data', error);
    next(false);
  });
}

const clients = [
  {
    path: '/local-client',
    name: 'local-client',
    meta: {
      isAuth: true,
      layout: "cs-dashboard",
    },
    component: () => import(`@/views/clients/CsLocalClients`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/archive-client',
    name: 'archive-client',
    meta: {
      isAuth: true,
      layout: "cs-dashboard",
    },
    component: () => import(`@/views/clients/CsArchiveClients`),
    beforeEnter: [guardRoute],
  },
];

const operators = [
  {
    path: '/operators',
    name: 'operators',
    meta: {
      isAuth: true,
      layout: "cs-dashboard",
    },
    component: () => import(`@/views/operators/CsOperators`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/add-operator',
    name: 'add-operator',
    meta: {
      isAuth: true,
      layout: "cs-dashboard",
    },
    component: () => import(`@/views/operators/CsAddOperator`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/:operatorId/edit-operator',
    name: 'edit-operator',
    meta: {
      isAuth: true,
      layout: "cs-dashboard",
    },
    component: () => import(`@/views/operators/CsEditOperator`),
    beforeEnter: [guardRoute],
  },
];

const dashboards = [
  {
    path: '/dashboard/:dashboardType?',
    name: 'dashboard',
    meta: {
      isAuth: true,
      layout: "cs-dashboard",
    },
    component: () => import(`@/views/dashboard/CsDashboard`),
    beforeEnter: [guardRoute],
  },
];

const reports = [
  {
    path: '/:userId/reports/:bureau?',
    name: 'reports',
    meta: {
      isAuth: true,
      hasSiteBar: true,
      layout: 'cs-main',
    },
    component: () => import(`@/views/reports/client/CsReports`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/:userId/business-reports/:bureauId',
    name: 'business-reports',
    meta: {
      isAuth: true,
      hasSiteBar: true,
      layout: 'cs-main',
    },
    component: () => import(`@/views/reports/business/CsBusinessReports`),
    beforeEnter: [guardRoute],
  },
];

const profiles = [
  {
    path: '/:userId/profile',
    name: 'profile',
    meta: {
      isAuth: true,
      hasSiteBar: true,
      layout: 'cs-main',
    },
    component: () => import(`@/views/profiles/CsProfile`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/create-local-client',
    name: 'create-local-client',
    meta: {
      isAuth: true,
      hasSiteBar: false,
      layout: 'cs-main',
    },
    component: () => import(`@/views/profiles/CsCreateProfile`),
    beforeEnter: [guardRoute],
  },
];

const companies = [
  {
    path: '/:userId/company/:companyId?',
    name: 'companies',
    meta: {
      isAuth: true,
      hasSiteBar: true,
      layout: 'cs-main',
    },
    component: () => import(`@/views/companies/CsCompanies`),
    beforeEnter: [guardRoute],
  },
];

const bureaus = [
  {
    path: '/:userId/bureau/connected',
    name: 'bureau-connected',
    meta: {
      isAuth: true,
      hasSiteBar: true,
      layout: "cs-main",
      component: 'cs-connected-bureau',
    },
    component: () => import(`@/views/bureaus/CsBureaus`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/:userId/bureau/process',
    name: 'bureau-process',
    meta: {
      isAuth: true,
      hasSiteBar: true,
      layout: "cs-main",
      component: 'cs-process-bureau',
    },
    component: () => import(`@/views/bureaus/CsBureaus`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/:userId/bureau/unsynchronized',
    name: 'bureau-unsynchronized',
    meta: {
      isAuth: true,
      hasSiteBar: true,
      layout: "cs-main",
      component: 'cs-unsynchronized-bureau',
    },
    component: () => import(`@/views/bureaus/CsBureaus`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/:userId/bureau/detail/:bureau',
    name: 'bureau-detail',
    meta: {
      isAuth: true,
      layout: "cs-main",
      component: 'cs-bureau-detail',
    },
    component: () => import(`@/views/bureaus/CsBureaus`),
    beforeEnter: [guardRoute],
  },
];

const businessBureaus = [
  {
    path: '/:userId/business/:businessId/bureau/:bureauId/detail',
    name: 'business-bureau-detail',
    meta: {
      isAuth: true,
      layout: "cs-main",
      component: 'cs-business-bureau-detail',
    },
    component: () => import(`@/views/business-bureaus/CsBusinessBureaus`),
    beforeEnter: [guardRoute],
  },
];

const admin = [
  {
    path: '/admin/setting-reasons',
    name: 'setting-reasons',
    meta: {
      isAuth: true,
      isAdmin: true,
      layout: 'cs-dashboard',
    },
    component: () => import('@/views/admin/CsSettingReasons.vue'),
    beforeEnter: [guardRoute],
  },
];

const routes = [
  {
    path: '/show-file/:filepath',
    name: 'show-file',
    meta: {
      isAuth: true,
      layout: 'cs-dashboard',
    },
    component: () => import(`@/components/CsShowFile`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/',
    name: 'home',
    meta: {
      isAuth: true,
      layout: 'cs-dashboard',
    },
    component: () => import(`@/views/dashboard/CsDashboard`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/settings',
    name: 'settings',
    meta: {
      isAuth: true,
      layout: 'cs-dashboard',
    },
    component: () => import('@/views/profiles/CsSetting'),
    beforeEnter: [guardRoute],
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    meta: {
      isAuth: false,
      layout: "cs-auth",
    },
    component: () => import(`@/views/auth/CsSingIn`),
    beforeEnter: [guardRoute],
  },
  // {
  //   path: '/sing-up',
  //   name: 'sing-up',
  //   meta: {
  //     isAuth: false,
  //     layout: "cs-main",
  //   },
  //   component: () => import(`@/views/auth/CsSingUp`),
  //   beforeEnter: [guardRoute],
  // },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    meta: {
      isAuth: false,
      layout: "cs-auth",
    },
    component: () => import(`@/views/auth/CsForgotPassword`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/reset-password/:rememberToken',
    name: 'reset-password',
    meta: {
      isAuth: false,
      layout: "cs-auth",
    },
    component: () => import(`@/views/auth/CsResetPassword`),
    beforeEnter: [guardRoute],
  },
  ...clients,
  ...operators,
  ...dashboards,
  ...bureaus,
  ...businessBureaus,
  ...reports,
  ...companies,
  ...profiles,
  ...admin,
];

const index = createRouter({
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (process.env.NODE_ENV === 'development') {
      console.info('process.env', process.env);
      console.groupCollapsed('Scroll Behavior:');
      console.info('to', to);
      console.info('from', from);
      console.info('savedPosition', savedPosition);
      console.groupEnd();
    }
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
  },
  linkActiveClass: 'active',
  history: createWebHistory(process.env.BASE_URL)
});
export default index;
